import anime from "animejs";
import "intersection-observer";
import Foresight from 'foresight';

function main() {
  const foresight = new Foresight();

  anime
    .timeline()
    .add({
      targets: ".section--contactus .white-mask",
      scaleX: [1, 0],
      easing: "easeOutQuart",
      duration: 800
    })
    .add({
      targets: ".section--contactus .column-img-container",
      translateX: [100, 0],
      easing: "easeOutQuart",
      duration: 800,
      offset: "-=800"
    })
    .add({
      targets: ".section--contactus .box--blue .box-white-mask",
      scaleX: [1, 0],
      easing: "easeOutQuart",
      duration: 800,
      offset: "-=400"
    })
    .add({
      targets: ".section--contactus .box--blue",
      translateX: ["225%", "205%"],
      translateY: ["-80%", "-80%"],
      easing: "easeOutQuart",
      duration: 800,
      offset: "-=800"
    })
    .add({
      targets: ".section--contactus .line-texture",
      translateX: ["200%", "50%"],
      translateY: ["-90%", "-90%"],
      easing: "easeOutQuart",
      duration: 800,
      offset: "-=600"
    })
    .add({
      targets: ".section--contactus .uoa-contacts-container",
      opacity: [0,1],
      easing: "easeOutQuart",
      duration: 400,
      offset: "-=600"
    })

  const toAnimate = document.querySelectorAll(".content-to-animate");
  const animObserver = new IntersectionObserver(
    entries => {
      entries.forEach((entry, index) => {
        if (
          entry.intersectionRatio > 0 &&
          !entry.target.classList.contains("is-animated")
        ) {
          anime({
            targets: entry.target,
            opacity: [0, 1],
            easing: "easeOutQuart",
            duration: 600,

            delay: () => 75 * index,

            begin: () => entry.target.classList.add("is-animated"),

            complete: () => {
              if (entry.target.id == "yellow-info-container") {
                animateNumbers();
              }
            }
          });
        }
      });
    },
    { threshold: 0.35 }
  );

  setTimeout(() => {
    toAnimate.forEach(el => {
      animObserver.observe(el);
    });
  }, 400);
}

export default main;
