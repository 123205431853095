import anime from 'animejs';
import 'intersection-observer';
import CountUp from 'countup.js';

function main() {
  anime
    .timeline()
    .add({
      targets: '.section--experience-menu .texture-box',
      width: [0, 225],
      easing: 'easeOutQuart',
      duration: 400,
    })
    .add({
      targets: '.container-header-experience',
      width: [0, 550],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=400',
    })
    .add({
      targets: '.line-texture-bg',
      width: [0, '40%'],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=800',
    })
    .add({
      targets: '.line-texture-container',
      width: [0, '100%'],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=800',
    })
    .add({
      targets: '.subpage-experience p',
      translateX: [-50, 0],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 300,
      offset: '-=300',
      delay: function (el, i, l) {
        return i * 75;
      },
    })
    .add({
      targets: '#subpage-exp',
      scaleX: [0, 1],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 300,
      offset: '-=300',
    });

  const toAnimate = document.querySelectorAll('.content-to-animate');
  const animObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry, index) => {
        if (
          entry.intersectionRatio > 0 &&
          !entry.target.classList.contains('is-animated')
        ) {
          anime({
            targets: entry.target,
            translateY: [100, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 600,

            delay: () => 75 * index,

            begin: () => entry.target.classList.add('is-animated'),

            complete: () => {
              if (entry.target.id == 'yellow-info-container') {
                animateNumbers();
              }
            },
          });
        }
      });
    },
    { threshold: 0.35 }
  );

  // mobile select menu
  if (window.innerWidth < 768) {
    const menuSelect = document.querySelector(
      '.section--experience-menu-minimal select'
    );

    menuSelect.addEventListener('change', (e) => {
      const options = menuSelect.querySelectorAll('option');

      options.forEach((opt) => {
        if (opt.value === menuSelect.value)
          window.location.href = opt.getAttribute('data-href');
      });
    });
  }

  // animate numbers
  function animateNumbers() {
    //Count up settings
    var easingFn = function (t, b, c, d) {
      var ts = (t /= d) * t;
      var tc = ts * t;
      return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
    };
    var options = {
      // useEasing: true,
      // easingFn: easeOutExpo,
      useGrouping: true,
      separator: ',',
      decimal: '.',
    };
    var options2 = {
      // useEasing: true,
      // easingFn: easeOutExpo,
      useGrouping: true,
      separator: ',',
      decimal: '.',
      suffix: 'M',
    };
    var countup1 = new CountUp('info-number-hotels', 0, 1080, 0, 2, options);
    var countup2 = new CountUp('info-number-fnbs', 0, 84, 0, 2.5, options);
    var countup3 = new CountUp('info-number-cctvs', 0, 2218, 0, 2, options);
    var countup4 = new CountUp(
      'info-number-emergency',
      0,
      471,
      0,
      2.2,
      options
    );
    var countup5 = new CountUp('info-number-green', 0, 68796, 0, 2, options);
    var countup6 = new CountUp('info-number-parking', 0, 10000, 0, 2, options);
    var countup7 = new CountUp('info-number-homes', 0, 5512, 0, 2, options);
    var countup8 = new CountUp(
      'info-number-commercial-blocks',
      0,
      28,
      0,
      3.2,
      options
    );
    var countup9 = new CountUp(
      'info-number-retail-lots',
      0,
      141,
      0,
      2.7,
      options
    );
    var countup10 = new CountUp('info-number-event', 0, 2100, 0, 2.7, options);
    var countup11 = new CountUp('info-number-guards', 0, 209, 0, 2.7, options);
    var countup12 = new CountUp('info-number-office', 0, 5, 0, 5, options2);
    if (
      !countup1.error ||
      !countup2.error ||
      !countup3.error ||
      !countup4.error ||
      !countup5.error ||
      !countup6.error ||
      !countup7.error ||
      !countup8.error ||
      !countup9.error ||
      !countup10.error ||
      !countup11.error ||
      !countup12.error
    ) {
      countup1.start();
      countup2.start();
      countup3.start();
      countup4.start();
      countup5.start();
      countup6.start();
      countup7.start();
      countup8.start();
      countup9.start();
      countup10.start();
      countup11.start();
      countup12.start();
    }
  }

  setTimeout(() => {
    toAnimate.forEach((el) => {
      animObserver.observe(el);
    });
  }, 400);

  //testimonials drop down
  const boxes = document.querySelectorAll('.directory-item');

  setBoxHeight();
  bindBoxClickEvent();

  window.addEventListener('resize', setBoxHeight);

  function bindBoxClickEvent() {
    boxes.forEach((box) =>
      box.addEventListener('click', () => boxClickEvent(box))
    );
  }

  function boxClickEvent(box) {
    if (!box.classList.contains('is-active')) {
      closeAllBoxContent();
      openBoxContent(box);
    } else {
      closeBoxContent(box);
    }
  }

  function openBoxContent(box) {
    const content = box.querySelector('.item--content');

    box.classList.add('is-active');

    content.style.zIndex = '2';

    anime.remove(content);

    anime({
      targets: content,
      height: content.getAttribute('data-height'),

      duration: 600,
      easing: 'easeOutQuart',
    });
  }

  function closeBoxContent(box) {
    const content = box.querySelector('.item--content');

    box.classList.remove('is-active');

    content.style.zIndex = '1';

    anime.remove(content);

    anime({
      targets: content,
      height: 0,

      duration: 400,
      easing: 'easeOutQuart',
    });
  }

  function closeAllBoxContent() {
    boxes.forEach((box) => closeBoxContent(box));
  }

  function setBoxHeight() {
    boxes.forEach((box) => {
      const content = box.querySelector('.item--content');

      content.style.height = '';
      content.setAttribute('data-height', `${content.clientHeight}`);
      content.style.height = 0;
    });
  }
}

export default main;
