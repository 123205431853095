import anime from 'animejs';
import 'intersection-observer';
import Rellax from 'rellax';
import CountUp from 'countup.js';

function main() {
  if (window.innerWidth > 768) {
    // rellax init settings
    window.onload = function () {
      let rellax = new Rellax('.rellax');
    };
  }

  anime
    .timeline()
    .add({
      targets: '.section--aboutus-splash .white-mask',
      scaleX: [1, 0],
      easing: 'easeOutQuart',
      duration: 800,
    })
    .add({
      targets: '.section--aboutus-splash .column-img-container',
      translateX: [100, 0],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=800',
    })
    .add({
      targets: '.section--aboutus-splash .box--yellow .box-white-mask',
      scaleX: [1, 0],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=400',
    })
    .add({
      targets: '.section--aboutus-splash .box--yellow',
      translateX: ['365%', '345%'],
      translateY: ['-80%', '-80%'],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=800',
    })
    .add({
      targets: '.section--aboutus-splash .line-texture',
      translateX: ['100%', '-5%'],
      translateY: ['40%', '40%'],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=600',
    })
    .add({
      targets: '.section--aboutus h1, .welcome-line',
      translateX: [100, 0],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=800',
    })
    .add({
      targets: '.section--aboutus p',
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=400',
    });

  const toAnimate = document.querySelectorAll('.content-to-animate');
  const animObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry, index) => {
        if (
          entry.intersectionRatio > 0 &&
          !entry.target.classList.contains('is-animated')
        ) {
          anime({
            targets: entry.target,
            translateY: [100, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 600,

            delay: () => 75 * index,

            begin: () => entry.target.classList.add('is-animated'),

            complete: () => {
              if (entry.target.id == 'yellow-info-container') {
                animateNumbers();
              }
            },
          });
        }
      });
    },
    { threshold: 0.35 }
  );

  function animateNumbers() {
    //Count up settings
    var easingFn = function (t, b, c, d) {
      var ts = (t /= d) * t;
      var tc = ts * t;
      return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
    };
    var options = {
      // useEasing: true,
      // easingFn: easeOutExpo,
      useGrouping: true,
      separator: ',',
      decimal: '.',
    };
    var options2 = {
      // useEasing: true,
      // easingFn: easeOutExpo,
      useGrouping: true,
      separator: ',',
      decimal: '.',
      suffix: 'M',
    };
    var countup1 = new CountUp(
      'info-number-commercial-blocks',
      0,
      28,
      0,
      2.5,
      options
    );
    var countup2 = new CountUp('info-number-homes', 0, 5512, 0, 1.5, options);
    var countup3 = new CountUp('info-number-green', 0, 68796, 0, 2, options);
    var countup4 = new CountUp('info-number-office', 0, 5, 0, 5, options2);

    if (
      !countup1.error ||
      !countup2.error ||
      !countup3.error ||
      !countup4.error
    ) {
      countup1.start();
      countup2.start();
      countup3.start();
      countup4.start();
    }
  }

  setTimeout(() => {
    toAnimate.forEach((el) => {
      animObserver.observe(el);
    });
  }, 400);
}

export default main;
