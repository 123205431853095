import Siema from 'siema';
import anime from 'animejs';
import 'intersection-observer';


// make function true when add banner event
function main() {
  const festiveBannerUsed = hasFestiveBanner();

  // Initialize Siema
  const siema = new Siema({
    selector: '.splash-bg',
    perPage: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    draggable: false,
    duration: 1000,
    loop: true,
    onChange: () => {
      document
        .querySelector('.splash-content')
        .classList.remove('noTransitions');
        // Hide all splash content
      if (festiveBannerUsed) {
        if (getCurrentFestive()) {
          document
            .querySelector('.discoverMoreBtn')
            .classList.add('hideFestiveBtn');
          document
            .querySelector('.splash-content')
            .classList.add('hideSplashContent');
          document
            .querySelector('.splash-content')
            .classList.add('noTransitions');
          setTimeout(() => {
            document
              .querySelectorAll('.splash-slide')[1]
              .classList.add('is-current');
          }, 300);
        } else {
          document
            .querySelector('.discoverMoreBtn')
            .classList.remove('hideFestiveBtn');
          document
            .querySelector('.splash-content')
            .classList.remove('hideSplashContent');
        }
      }

      // Not Hide just change colour
      // if (festiveBannerUsed) {
      //   //Change getcurrentslide comparison to current data-content target
      //   if (getCurrentSlide() == 'vote-win') {
      //     const parent = document.querySelector('.splash-content')
      //     parent.querySelectorAll('h1, a, p').forEach((item, index) =>{
      //       item.classList.add("grayText");
      //       item.classList.add("grayBorder");
      //     })
      //     $('.content-row').css('opacity', '0');
      //   } else {
      //     const parent = document.querySelector('.splash-content')
      //     parent.querySelectorAll('h1, a, p').forEach((item, index) =>{
      //       item.classList.remove("grayText");
      //       item.classList.remove("grayBorder");
      //     })
      //   }
      // }

      // Add, change colour & edit link for button discover more
      // if (festiveBannerUsed) {
      //   if (getCurrentSlide() == 'appreciation-frontliners') {
      //     document
      //       .querySelector('.content-row')
      //       .classList.add('hide-title');
      //     document
      //       .querySelector('.splash-content p')
      //       .classList.add('hide-title');
      //     document
      //       .querySelector('.discoverMoreBtn')
      //       .classList.add('gold-theme');
      //     document.getElementById("link-banner").href = "https://www.facebook.com/watch/?v=244080883659314";
          
      //     setTimeout(() => {
      //       document
      //         .querySelectorAll('.splash-slide')[1]
      //         .classList.add('is-current');
      //     }, 300);
      //   } else {
      //     document
      //       .querySelector('.content-row')
      //       .classList.remove('hide-title');
      //     document
      //       .querySelector('.splash-content p')
      //       .classList.remove('hide-title');
      //     document
      //       .querySelector('.discoverMoreBtn')
      //       .classList.remove('gold-theme');
      //     document.getElementById("link-banner").href = "/about-us";
      //   }
      // }
    },

    // First Loop
    onInit: () => {
      if (festiveBannerUsed) {
        document
          .querySelector('.discoverMoreBtn')
          .classList.add('hideFestiveBtn');
        document
          .querySelector('.splash-content')
          .classList.add('hideSplashContent');
        setTimeout(() => {
          document
            .querySelectorAll('.splash-slide')[1]
            .classList.add('is-current');
        }, 300);
      } else {
        setTimeout(() => {
          document.querySelector('.splash-content').classList.remove('noTransitions'); //slide in landing big title
          document
            .querySelectorAll('.splash-slide')[1]
            .classList.add('is-current');

          anime({
            targets: '.splash-content .content-row',
            translateX: [300, 0],
            opacity: 1,
            easing: 'easeOutQuad',
            duration: 1500,
            delay: function(el, i, l) {
              return i * 100;
            }
          });
        }, 300);
      }
    }
  });

  function initCommunityCarousel() {
    const slidesLength = document.querySelectorAll('.community-carousel > *').length
    if (slidesLength == 0) return
    
    const siema2 = new Siema({
      selector: '.community-carousel',
      perPage: {
        800: 2,
        1200: 3
      },
      easing: 'cubic-bezier(0.77, 0, 0.175, 1)',
      draggable: true,
      duration: 600,
      loop: true,
      onChange: () => {
        const slider2 = document.querySelector('.community-carousel');
        slider2.classList.toggle('is-up');
        slider2.classList.toggle('is-down');
      }
    });
  
    let isSliding = false;
    document
      .getElementById('leftNavBtn')
      .addEventListener('click', () => siema2.prev());
    document.getElementById('rightNavBtn').addEventListener('click', () => {
      if (isSliding) return;
      siema2.next();
      isSliding = true;
      setTimeout(() => {
        isSliding = false;
      }, 400);
    });
  }
  initCommunityCarousel()

  function getCurrentSlide() {
    const currentSlide = siema.innerElements[siema.currentSlide].getAttribute(
      'data-content'
    );
    return currentSlide;
  }

  function getCurrentFestive() {
    const currentSlide = siema.innerElements[siema.currentSlide].hasAttribute(
      'data-festive'
    );
    return currentSlide;
  }

  function hasFestiveBanner() {
    const festiveBanners = document.querySelectorAll('.splash-bg .splash-slide[data-festive]')
    const hasFestive = festiveBanners.length > 0 ? true : false
    return hasFestive
  }

  // Parallax effect settings
  setInterval(() => {
    const allSlides = document.querySelectorAll('.splash-slide');

    siema.next();

    const currentContent = siema.innerElements[siema.currentSlide].getAttribute(
      'data-content'
    );
    const currentSlides = Array.from(allSlides).filter(
      el => el.getAttribute('data-content') === currentContent
    );

    allSlides.forEach(el => el.classList.remove('is-current'));
    currentSlides.forEach(el => el.classList.add('is-current'));

    const slideWrappers = document.querySelectorAll('.splash-bg > div > div');
    slideWrappers.forEach(el => el.classList.remove('is-previous'));
    slideWrappers[siema.currentSlide].classList.add('is-previous');
  }, 5000);

  // Community Section Intersection observer settings
  const communitySections = document.querySelectorAll(
    '.section--landing-community'
  );
  let communityAnimated = false;

  let observer = new IntersectionObserver(entries => {
    entries.forEach(
      entry => {
        if (entry.intersectionRatio > 0 && !communityAnimated) {
          communityAnimated = true;
          document
            .querySelector('.community-carousel')
            .classList.add('is-opaque');
          anime({
            targets: '.community-header',
            translateX: [500, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 1500
          });
          anime({
            targets: '.yellow-box',
            width: [0, 450],
            easing: 'easeOutQuart',
            duration: 1750,
            delay: 600
          });
          anime({
            targets: '.texture-box',
            translateX: ['20%', '0'],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 1500,
            delay: 600
          });
          anime({
            targets: '.community-carousel > div > div:not(:nth-child(5))',
            translateY: [50, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 1000,
            delay: 500
          });
          anime({
            targets: '.carousel-navigation',
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 1000,
            delay: 2000
          });
          anime({
            targets: '.community-carousel > div > div:nth-child(5)',
            translateY: [-50, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 1000,
            delay: 500
          });
        }
      },
      { threshold: 0.35 }
    );
  });

  communitySections.forEach(image => {
    observer.observe(image);
  });

  // Getting Here Section Intersection observer settings
  const gettingHereSections = document.querySelectorAll(
    '.section--landing-gettinghere'
  );
  let gettingHereAnimated = false;

  let observer2 = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0 && !gettingHereAnimated) {
        gettingHereAnimated = true;

        anime({
          targets: '.header--gettinghere h1, .header--gettinghere div',
          translateX: [-500, 0],
          opacity: [0, 1],
          easing: 'easeOutQuart',
          duration: 1500,
          delay: function(el, i, l) {
            return i * 600;
          }
        });

        anime({
          targets: '.box--green, .box--green > div > p, .box--green > div > a',
          translateX: [500, 0],
          easing: 'easeOutQuart',
          duration: 1500,
          delay: function(el, i, l) {
            return i * 150;
          }
        });
      }
    });
  });

  gettingHereSections.forEach(image => {
    observer2.observe(image);
  });
}

export default main;
