import anime from 'animejs';
import 'intersection-observer';

function main() {
  anime
    .timeline()
    .add({
      targets: '.section--parking-menu .texture-box',
      width: [0, 225],
      easing: 'easeOutQuart',
      duration: 400
    })
    .add({
      targets: '.header-parking',
      width: [0, '100%'],
      easing: 'easeOutQuart',
      duration: 1500,
      offset: '-=400'
    })
    .add({
      targets: '.line-texture',
      width: [0, '30%'],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=1000'
    });

  const toAnimate = document.querySelectorAll('.content-to-animate');
  const animObserver = new IntersectionObserver(
    entries => {
      entries.forEach((entry, index) => {
        if (
          entry.intersectionRatio > 0 &&
          !entry.target.classList.contains('is-animated')
        ) {
          anime({
            targets: entry.target,
            translateY: [100, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 600,

            delay: () => 75 * index,

            begin: () => entry.target.classList.add('is-animated'),

            complete: () => {
              if (entry.target.id == 'yellow-info-container') {
                animateNumbers();
              }
            }
          });
        }
      });
    },
    { threshold: 0.35 }
  );

  setTimeout(() => {
    toAnimate.forEach(el => {
      animObserver.observe(el);
    });
  }, 400);

  const tabsNavigation = document.querySelectorAll('.navigation-tabs a')
  const allTabs = document.querySelectorAll('.tab-pane')
  tabsNavigation.forEach( nav => {
    nav.addEventListener('click', (event) => {
      event.preventDefault()
      if (nav.classList.contains('active')) return

      const href = nav.getAttribute('href')
      const targetEl = document.querySelector(href)
      tabsNavigation.forEach( el => el.classList.remove('active') )
      allTabs.forEach( el => el.classList.remove('active') )
      nav.classList.add('active')
      targetEl.classList.add('active')
    })
  })

  const moreButton = document.querySelector('[data-target]')
  if (moreButton) {
    moreButton.addEventListener('click', function(e) {
      e.preventDefault()
      const targetTabValue = this.getAttribute('href')
      const targetNav = Array.from(tabsNavigation).filter( el => el.getAttribute('href') == targetTabValue )
      
      tabsNavigation.forEach( el => el.classList.remove('active') )
      allTabs.forEach( el => el.classList.remove('active') );
      targetNav[0].classList.add('active')
      document.querySelector(targetTabValue).classList.add('active')
    })
  }
}

export default main;
