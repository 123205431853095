import anime from "animejs";
import "intersection-observer";

function main() {
  anime
  .timeline()
  .add({
    targets: ".section--community-menu .texture-box",
    width: [0, 225],
    easing: "easeOutQuart",
    duration: 400
  })
  .add({
    targets: ".header-community",
    width: [0, 550],
    easing: "easeOutQuart",
    duration: 1500,
    offset: "-=400"
  })
  .add({
    targets: ".header-detail-text",
    opacity: [0,1],
    easing: "easeOutQuart",
    duration: 800,
    offset: "-=600"
  })
  .add({
    targets: ".line-texture",
    width: [0, "30%"],
    opacity: [0, 1],
    easing: "easeOutQuart",
    duration: 800,
    offset: "-=800"
  })
  .add({
    targets: ".filters-container",
    width: [0, 400],
    easing: "easeOutQuart",
    duration: 1200,
    offset: "-=1700"
  })
  
  const toAnimate = document.querySelectorAll(".content-to-animate");
  const animObserver = new IntersectionObserver(
    entries => {
      entries.forEach((entry, index) => {
        if (
          entry.intersectionRatio > 0 &&
          !entry.target.classList.contains("is-animated")
        ) {
          anime({
            targets: entry.target,
            translateY: [100, 0],
            opacity: [0, 1],
            easing: "easeOutQuart",
            duration: 600,

            delay: () => 75 * index,

            begin: () => entry.target.classList.add("is-animated"),

            complete: () => {
              console.log(entry.target.id)
              if (entry.target.id == "yellow-info-container") {
                animateNumbers();
              }
            }
          });
        }
      });
    },
    { threshold: 0.35 }
  );

  setTimeout(() => {
    toAnimate.forEach(el => {
      animObserver.observe(el);
    });
  }, 400);
}

export default main;
