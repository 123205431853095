import panZoom from "../vendors/panZoom/panZoom.js";
import anime from "animejs";
import throttle from "lodash/throttle";

export default function activateInteractiveMap() {
  const area = document.querySelector("#map-container-inner");
  const controller = panZoom(area, {
    maxZoom: 4,
    minZoom: 1,
    smoothScroll: false
  });
  if (
    document.querySelector("body").classList.contains("page-shuttleservice")
  ) {
    // const controller = panZoom(area, {
    //   maxZoom: 4,
    //   minZoom: 1,
    //   smoothScroll: false,
    //   beforeWheel: function(e) {
    //     var shouldIgnore = !e.altKey;
    //     return shouldIgnore;
    //   }
    // });
    // controller.on("panstart", function(e) {
    //   controller.pause();
    // });
    zoomToBangsarSouth(controller);
  }

  // window.addEventListener(
  //   "resize",
  //   throttle(() => {
  //     zoomToBangsarSouth();
  //   }, 1000)
  // );

  const mapElementButtons = document.querySelectorAll(".route-btn");
  const mapElements = document.querySelectorAll(".routes-container");

  mapElementButtons.forEach(button => {
    button.addEventListener("click", () => {
      const contentToShow = button.getAttribute("data-content");

      toggleMapElementButtons(button);
      toggleMapElements(contentToShow);
    });
  });

  function zoomToBangsarSouth(controller) {
    const interactiveMapWidth = document.querySelector("#interactive-map")
      .offsetWidth;
    const interactiveMapheight = document.querySelector("#interactive-map")
      .offsetHeight;
    const initialX = interactiveMapWidth / 2.7;
    const initialY = interactiveMapheight / 1.15;
    controller.zoomAbs(initialX, initialY, 3);
  }

  function toggleMapElementButtons(button) {
    mapElementButtons.forEach(el => el.classList.remove("active"));
    button.classList.add("active");
  }

  function toggleMapElements(content) {
    const elementToShow = document.querySelector(
      `.routes-container[data-content="${content}"]`
    );

    // hide all other
    mapElements.forEach(el => el.classList.remove("active"));
    // show line
    elementToShow.classList.add("active");
  }

  /**
   * This commented code is used to get each path percent to be used down below
   * that's how those weird ass decimals are gotten
   */
  // const lines = document.querySelectorAll( '.path-1-segment, .path-2-segment, .path-3-segment, .path-4-segment' );

  // let totalLength = 0;
  // lines.forEach(line => (totalLength += line.getTotalLength()));

  // lines.forEach((line, index) => {
  //   console.log( 'line' + index, (line.getTotalLength() / totalLength) * 100 + '%' );
  // });

  const totalLineDuration = 15000;
  const offsetTrailingLine = 800;

  anime.timeline({
    easing: "linear",
    loop: true
  }).add({
    targets: ".shsp-path",
    strokeDashoffset: [2000, 0],
    opacity: [1,1],
    duration: totalLineDuration * 0.25
  });

  anime.timeline({
    easing: "linear",
    loop: true
  }).add({
    targets: ".pjsa-path",
    strokeDashoffset: [2000, 0],
    opacity: [1,1],
    duration: totalLineDuration * 0.25
  });

  anime.timeline({
    easing: "linear",
    loop: true
  }).add({
    targets: ".klsr-path",
    strokeDashoffset: [2000, 0],
    opacity: [1,1],
    duration: totalLineDuration * 0.25
  });

  anime.timeline({
    easing: "linear",
    loop: true
  }).add({
    targets: ".mkds-path",
    strokeDashoffset: [2000, 0],
    opacity: [1,1],
    duration: totalLineDuration * 0.25
  });

  anime
    .timeline({
      easing: "linear",
      loop: true
    })
    .add({
      targets: ".path-1-segment",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.27
    })
    .add({
      targets: ".path-1-trailing",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.27,
      offset: `-=${totalLineDuration * 0.27 - offsetTrailingLine}`
    })
    .add({
      targets: ".path-2-segment",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.28,
      offset: `-=${offsetTrailingLine}`
    })
    .add({
      targets: ".path-2-trailing",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.28,
      offset: `-=${totalLineDuration * 0.28 - offsetTrailingLine}`
    })
    .add({
      targets: ".path-3-segment",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.13,
      offset: `-=${offsetTrailingLine}`
    })
    .add({
      targets: ".path-3-trailing",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.13,
      offset: `-=${totalLineDuration * 0.13 - offsetTrailingLine}`
    })
    .add({
      targets: ".path-4-segment",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.31,
      offset: `-=${offsetTrailingLine}`
    })
    .add({
      targets: ".path-4-trailing",
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: [1, 1],
      duration: totalLineDuration * 0.31,
      offset: `-=${totalLineDuration * 0.31 - offsetTrailingLine}`
    });

  var zoomInBtn = document.querySelector("#zoomInBtn");
  if (zoomInBtn) {
    document.querySelector("#zoomInBtn").addEventListener("click", () => {
      controller.zoomTo(area.clientWidth / 2, area.clientHeight / 2, 1.3);
    });
  }

  var zoomOutBtn = document.querySelector("#zoomOutBtn");
  if (zoomOutBtn) {
    document.querySelector("#zoomOutBtn").addEventListener("click", () => {
      controller.zoomTo(area.clientWidth / 3, area.clientHeight / 3, 0.7);
    });
  }

  var resetBtn = document.querySelector("#resetBtn");
  if (resetBtn) {
    document.querySelector("#resetBtn").addEventListener("click", () => {
      controller.resetZoom();
    });
  }
}
