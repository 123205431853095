import anime from 'animejs';
import 'intersection-observer';
import Siema from 'siema';
import CountUp from 'countup.js';

function main() {
  anime
    .timeline()
    .add({
      targets: '.section--community-menu .texture-box',
      width: [0, 225],
      easing: 'easeOutQuart',
      duration: 400,
    })
    .add({
      targets: '.header-community',
      width: [0, 550],
      easing: 'easeOutQuart',
      duration: 1500,
      offset: '-=400',
    })
    .add({
      targets: '.header-detail-text',
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=600',
    })
    .add({
      targets: '.line-texture',
      width: [0, '30%'],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 800,
      offset: '-=800',
    })
    .add({
      targets: '.filters-container',
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 1500,
      offset: '-=1700',
    });

  // Initialize Siema
  const siema = new Siema({
    selector: '.highlights-container',
    perPage: 1,
    easing: 'cubic-bezier(0.77, 0, 0.175, 1)',
    draggable: true,
    loop: true,
    duration: 600,
    onChange: () => {
      switch (siema.currentSlide) {
        case 0:
          removeTabHighlight();
          document.querySelector('#highlight-tab-1').classList.add('active');
          break;

        case 1:
          removeTabHighlight();
          document.querySelector('#highlight-tab-2').classList.add('active');
          break;

        case 2:
          removeTabHighlight();
          document.querySelector('#highlight-tab-3').classList.add('active');
          break;
      }
    },
  });

  const toAnimate = document.querySelectorAll('.content-to-animate');
  const animObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry, index) => {
        if (
          entry.intersectionRatio > 0 &&
          !entry.target.classList.contains('is-animated')
        ) {
          anime({
            targets: entry.target,
            translateY: [100, 0],
            opacity: [0, 1],
            easing: 'easeOutQuart',
            duration: 600,

            delay: () => 75 * index,

            begin: () => entry.target.classList.add('is-animated'),

            complete: () => {
              console.log(entry.target.id);
              if (entry.target.id == 'yellow-info-container') {
                animateNumbers();
              }
            },
          });
        }
      });
    },
    { threshold: 0.35 }
  );

  function animateNumbers() {
    //Count up settings
    var easingFn = function (t, b, c, d) {
      var ts = (t /= d) * t;
      var tc = ts * t;
      return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
    };
    var options = {
      // useEasing: true,
      // easingFn: easeOutExpo,
      useGrouping: true,
      separator: ',',
      decimal: '.',
    };
    var countup1 = new CountUp('info-number-hotel', 0, 672, 0, 2, options);
    var countup2 = new CountUp('info-number-event', 0, 2100, 0, 2, options);
    var countup3 = new CountUp('info-number-fnb', 0, 84, 0, 2.7, options);
    if (!countup1.error || !countup2.error || !countup3.error) {
      countup1.start();
      countup2.start();
      countup3.start();
    } else {
      console.error(countup1.error, countup2.error, countup3.error);
    }
  }

  setTimeout(() => {
    toAnimate.forEach((el) => {
      animObserver.observe(el);
    });
  }, 400);

  document
    .querySelector('.highlights-container')
    .addEventListener('mousedown', () => {
      stopSlide();
    });

  document.querySelector('#highlight-tab-1').addEventListener('click', () => {
    siema.goTo(0);
    stopSlide();
  });
  document.querySelector('#highlight-tab-2').addEventListener('click', () => {
    siema.goTo(1);
    stopSlide();
  });
  document.querySelector('#highlight-tab-3').addEventListener('click', () => {
    siema.goTo(2);
    stopSlide();
  });

  const slideInterval = setInterval(() => {
    siema.next();
  }, 3000);

  function stopSlide() {
    clearInterval(slideInterval);
  }

  function removeTabHighlight() {
    document.querySelector('#highlight-tab-1').classList.remove('active');
    document.querySelector('#highlight-tab-2').classList.remove('active');
    document.querySelector('#highlight-tab-3').classList.remove('active');
  }
}

export default main;
