import 'intersection-observer';
import '@babel/polyfill';
import 'nodelist-foreach-polyfill';

import anime from 'animejs';

import landingPageMain from './landingPage';
import experiencePageMain from './experiencePage';
import aboutusPageMain from './aboutusPage';
import communityPageMain from './communityPage';
import communitySinglePageMain from './communitySinglePage';
import gettingherePageMain from './gettingherePage';
import directoryPageMain from './directoryPage';
import contactusPageMain from './contactusPage';
import shuttleservicePageMain from './shuttleservicePage';
import parkingPageMain from './parkingPage';

// Toggle Navigation Menu
const navIcon = document.querySelector('.nav-toggle');
const navMenu = document.querySelector('nav');
const searchIcon = document.querySelector('#menu-search-btn');
const searchInput = document.querySelector('#menu-search-input');
const hamburgerIcon = document.querySelector('.hamburger');
const menuShadow = document.querySelector('header > div:nth-child(1)');

searchIcon.addEventListener('click', () => {
  searchInput.classList.toggle('is-active');

  if (searchInput.classList.contains('is-active')) {
    window.addEventListener('mouseup', enableClickClose);
  }
});

navIcon.addEventListener('click', () => {
  hamburgerIcon.classList.toggle('is-active');
  navMenu.classList.toggle('is-active');
  // Menu items animation
  if (navMenu.classList.contains('is-active')) {
    anime({
      targets: '.menu-items li',
      translateX: [300, 0],
      opacity: [0, 1],
      easing: 'easeOutQuart',
      duration: 400,
      delay: function (el, i, l) {
        return i * 75;
      },
    });

    // add click listener to close menu
    window.addEventListener('mouseup', enableClickClose);

    //remove shadow from menu button
    // document.querySelector("header > div:nth-child(1)").classList.remove('is-active');
    menuShadow.classList.remove('is-active');
  } else {
    menuShadow.classList.add('is-active');
    searchInput.classList.remove('is-active');
  }
});

function enableClickClose(e) {
  if (
    e.target !== document.querySelector('nav') &&
    !findUpTag(e.target, 'NAV') &&
    !findUpTag(e.target, 'HEADER')
  ) {
    //add shadow to menu button
    console.log('closed');
    document
      .querySelector('header > div:nth-child(1)')
      .classList.add('is-active');
    navMenu.classList.remove('is-active');
    hamburgerIcon.classList.remove('is-active');
    searchInput.classList.remove('is-active');
    window.removeEventListener('mouseup', enableClickClose);
  }
}

function findUpTag(el, tag) {
  while (el.parentNode) {
    el = el.parentNode;
    if (el.tagName === tag) return el;
  }
  return false;
}

// //Tingle start
// // instanciate new modal
// let modal = new tingle.modal({
//   footer: true,
//   stickyFooter: false,
//   closeMethods: ["overlay", "button", "escape"],
//   closeLabel: "Close",
//   cssClass: ["custom-class-1", "custom-class-2"],
//   onOpen: function() {
//     console.log("modal open");
//   },
//   onClose: function() {
//     console.log("modal closed");
//   },
//   beforeClose: function() {
//     return true; // close the modal
//     return false; // nothing happens
//   }
// });
// //Tingle end

// document.querySelector(".privacyBtn").addEventListener("click", () => {
//   axios({
//     method: "get",
//     url: "../privacy.php",
//     responseType: "document"
//   }).then(function(response) {
//     modal.setContent(response.data.body.querySelector(".privacy-content"));
//     modal.open();
//   });
// });

// document.querySelector(".disclaimerBtn").addEventListener("click", () => {
//   axios({
//     method: "get",
//     url: "../disclaimer.php",
//     responseType: "document"
//   }).then(function(response) {
//     modal.setContent(response.data.body.querySelector(".disclaimer-content"));
//     modal.open();
//   });
// });

if (document.body.classList.contains('page-landing')) {
  landingPageMain();
}

if (document.body.classList.contains('page-experience')) {
  experiencePageMain();
}

if (document.body.classList.contains('page-aboutus')) {
  aboutusPageMain();
}

if (document.body.classList.contains('page-community')) {
  communityPageMain();
}

if (document.body.classList.contains('page-community-single')) {
  communitySinglePageMain();
}

if (document.body.classList.contains('page-getting-here')) {
  gettingherePageMain();
}

if (document.body.classList.contains('page-directory')) {
  directoryPageMain();
}

if (document.body.classList.contains('page-contactus')) {
  contactusPageMain();
}

if (document.body.classList.contains('page-shuttleservice')) {
  shuttleservicePageMain();
}

if (document.body.classList.contains('page-parking')) {
  parkingPageMain();
}
