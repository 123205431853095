import anime from "animejs";
import "intersection-observer";

function main() {
  anime
    .timeline()
    .add({
      targets: ".section--directory-menu .texture-box",
      width: [0, 225],
      easing: "easeOutQuart",
      duration: 400
    })
    .add({
      targets: ".header-directory",
      width: [0, 525],
      easing: "easeOutQuart",
      duration: 1500,
      offset: "-=400"
    })
    .add({
      targets: ".header-detail-text",
      opacity: [0, 1],
      easing: "easeOutQuart",
      duration: 800,
      offset: "-=800"
    })
    .add({
      targets: ".logos-container",
      translateY: [100, 0],
      opacity: [0, 1],
      easing: "easeOutQuart",
      duration: 600,
      offset: "-=800"
    });

  const toAnimate = document.querySelectorAll(".content-to-animate");
  const animObserver = new IntersectionObserver(
    entries => {
      entries.forEach((entry, index) => {
        if (
          entry.intersectionRatio > 0 &&
          !entry.target.classList.contains("is-animated")
        ) {
          anime({
            targets: entry.target,
            translateY: [100, 0],
            opacity: [0, 1],
            easing: "easeOutQuart",
            duration: 600,

            delay: () => 75 * index,

            begin: () => entry.target.classList.add("is-animated"),

            complete: () => {
              if (entry.target.id == "yellow-info-container") {
                animateNumbers();
              }
            }
          });
        }
      });
    },
    { threshold: 0.35 }
  );

  setTimeout(() => {
    toAnimate.forEach(el => {
      animObserver.observe(el);
    });
  }, 400);

  //testimonials drop down
  const boxes = document.querySelectorAll(".directory-item");

  setBoxHeight();
  bindBoxClickEvent();

  window.addEventListener("resize", setBoxHeight);

  function bindBoxClickEvent() {
    boxes.forEach(box =>
      box.addEventListener("click", () => boxClickEvent(box))
    );
  }

  function boxClickEvent(box) {
    if (!box.classList.contains("is-active")) {
      setBoxHeight();
      closeAllBoxContent();
      openBoxContent(box);
    } else {
      closeBoxContent(box);
    }
  }

  function openBoxContent(box) {
    const content = box.querySelector(".item--content");

    box.classList.add("is-active");

    content.style.zIndex = "2";

    anime.remove(content);

    anime({
      targets: content,
      height: content.getAttribute("data-height"),

      duration: 600,
      easing: "easeOutQuart"
    });
  }

  function closeBoxContent(box) {
    const content = box.querySelector(".item--content");

    box.classList.remove("is-active");

    content.style.zIndex = "1";

    anime.remove(content);

    anime({
      targets: content,
      height: 0,

      duration: 600,
      easing: "easeOutQuart"
    });
  }

  function closeAllBoxContent() {
    boxes.forEach(box => closeBoxContent(box));
  }

  function setBoxHeight() {
    boxes.forEach(box => {
      const content = box.querySelector(".item--content");

      content.style.height = "";
      content.setAttribute("data-height", `${content.clientHeight}`);
      content.style.height = 0;
    });
  }
}

export default main;
